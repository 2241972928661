import ApiInstance from '../configs/apiInstance'

const getCafeDetails = async(id) => {
    try {
        const response = await ApiInstance.cafeService.get(`/${id}`);
        return response.data;
    } catch (error) {
        console.log(error);
    }
}

export default getCafeDetails;