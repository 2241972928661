import React, { useEffect, useState } from 'react';
import { Grid, Container, Box, Typography, Card, CardMedia, CardContent } from '@mui/material';
import { navigateToUrl } from 'single-spa';
import GetCafeImage from '../services/GetCafeImage';
import Filter from './Filter';

function CafeGrid({ cafes }) {
    const [cafeImages, setCafeImages] = useState({});
    const [filteredCafes, setFilteredCafes] = useState(cafes);
    const [searchQuery, setSearchQuery] = useState("");
    const [selectedTags, setSelectedTags] = useState([]);

    useEffect(() => {
        const fetchImages = async () => {
            if (!cafes || typeof cafes !== 'object') {
                console.error('Invalid cafes data:', cafes);
                return;
            }

            try {
                const images = await Promise.all(
                    Object.values(cafes).map(async (cafe) => {
                        const imageUrl = await GetCafeImage(cafe.coverImage);
                        return { [cafe.id]: imageUrl };
                    })
                );
                setCafeImages(images.reduce((acc, curr) => ({ ...acc, ...curr }), {}));
            } catch (error) {
                console.error('Error fetching images:', error);
            }
        };

        fetchImages();
    }, [cafes]);

    useEffect(() => {
        const filtered = cafes.filter((cafe) => {
            const matchesSearch = searchQuery === "" || cafe.name.toLowerCase().startsWith(searchQuery.toLowerCase());
                const matchesTags = selectedTags.length === 0 || selectedTags.every((tag) =>
                cafe.tags.some(tagObject => tagObject.tags.includes(tag))
            );
    
            return matchesSearch && matchesTags;
        });
        setFilteredCafes(filtered);
    }, [searchQuery, selectedTags, cafes]);
    

    const handleSearch = (query) => {
        setSearchQuery(query);
    };

    const handleTagChange = (tags) => {
        setSelectedTags(tags);
    };

    return (
        <Container maxWidth="xl">
            <Grid 
                container 
                spacing={2} 
                mt={2}
                sx={{
                    justifyContent: 'flex-start', 
                    alignItems: 'center',  
                }}
                >
                <Grid item xs={12}>
                    <Filter onSearch={handleSearch} onTagChange={handleTagChange}/>
                </Grid>

                {filteredCafes.length > 0 ? (
                    filteredCafes.map((cafe) => (
                        <Grid 
                            item key={cafe.id} 
                            xs={12} 
                            sm={6} 
                            md={4} 
                            lg={3} 
                            mb={2}
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',  
                            }}
                            >
                             <Card
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "flex-end",
                                    height: "200px",
                                    width: "300px",
                                    position: "relative",
                                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                                    transition: "transform 0.2s ease-in-out",
                                    "&:hover": {
                                        transform: "scale(1.02)",
                                    },
                                }}
                            >
                                <CardMedia
                                    component="img"
                                    height="250"
                                    image={cafeImages[cafe.id] || ""}
                                    alt={cafe.name}
                                    sx={{
                                        height: "100%",
                                        width: "100%",
                                        objectFit: "cover",
                                        zIndex: 0,
                                    }}
                                    onClick={() => navigateToUrl(`/cafes/${cafe.id}`)}
                                />
                                <Box
                                    sx={{
                                        position: "absolute",
                                        top: 0,
                                        left: 0,
                                        height: "100%",
                                        width: "100%",
                                        background: 'linear-gradient(to top, black, rgba(255, 255, 255, 0))',
                                        zIndex: 1,
                                        pointerEvents: "none",  
                                    }}
                                />
                                <CardContent
                                 sx={{
                                    position: "absolute",
                                    bottom: 0,
                                    left: 0,
                                    width: "100%",
                                    zIndex: 2,
                                    color: "white",
                                    p: 2,
                                }}
                                >
                                    <Typography fontFamily={'serif'} fontSize={"1.05rem"} sx={{ color: "white" }}>
                                    {cafe.name}
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    ))
                ) : (
                    <Grid item xs={12}>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                height: '500px',
                                width: '100%',
                                border: '1px solid',
                                borderImageSource: 'linear-gradient(45deg, rgba(80, 242, 196, 1), rgba(38, 208, 227, 1), rgba(117, 122, 255, 1))',
                                borderImageSlice: 1,
                            }}
                        >
                            <Typography fontFamily='serif' sx={{ fontSize: { xs: '0.9rem', sm: '1.5rem', md: '2.5rem', lg: '2.5rem' }, textAlign: 'center' }}>
                            We couldn’t find any cafes or restaurants matching your search.<br />Try adjusting your filters or explore other categories for fresh options!
                            </Typography>
                        </Box>
                    </Grid>
                )}
            </Grid>
        </Container>
    );
}

export default CafeGrid;
