import React, { useState } from "react";
import {
    Box,
    Grid,
    Container,
    TextField,
    InputAdornment,
    Select,
    MenuItem,
    Chip,
    OutlinedInput
} from "@mui/material";
import './styles/input.css'

const tagOptions = ["Silent", "Sweet", "Calm", "Fancy", "Party"];

function Filter({ onSearch, onTagChange }) {
    const [searchQuery, setSearchQuery] = useState("");
    const [selectedTags, setSelectedTags] = useState([]);

    const handleSearchChange = (event) => {
        const query = event.target.value;
        setSearchQuery(query);
        onSearch(query);
    };

    const handleTagChange = (event) => {
        const {
            target: { value },
        } = event;
        const selected = typeof value === "string" ? value.split(",") : value;
        setSelectedTags(selected);
        onTagChange(selected);
    };

    const handleTagDelete = (tagToDelete) => () => {
        const newSelectedTags = selectedTags.filter((tag) => tag !== tagToDelete);
        setSelectedTags(newSelectedTags);
        onTagChange(newSelectedTags);
    };

    return (
        <Container sx={{ mb: 4 }}>
            <Grid container spacing={2} alignItems="center">
                <Grid item xs={12} md={6}>
                    <TextField
                        className="gradient-textfield"
                        value={searchQuery}
                        onChange={handleSearchChange}
                        placeholder="Search"
                        variant="outlined"
                        fullWidth
                    />
                </Grid>

                <Grid item xs={12} md={6}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                        <Select
                            multiple
                            displayEmpty
                            value={selectedTags}
                            onChange={handleTagChange}
                            input={<OutlinedInput />}
                            renderValue={(selected) =>
                                selected.length === 0 ? <em>Tags</em> : null
                            }
                            className="gradient-select"
                            sx={{
                                width: "200px",
                                height: "55px",
                            }}
                        >
                            {tagOptions.map((tag) => (
                                <MenuItem key={tag} value={tag}>
                                    {tag}
                                </MenuItem>
                            ))}
                        </Select>

                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                            {selectedTags.map((tag) => (
                                <Chip
                                    key={tag}
                                    label={tag}
                                    onDelete={handleTagDelete(tag)}
                                    sx={{
                                        backgroundColor: "rgba(80, 242, 196, 0.5)",
                                        color: "black",
                                    }}
                                />
                            ))}
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </Container>
    );
}

export default Filter;
