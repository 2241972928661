import React, { useEffect, useState } from 'react';
import { Avatar, Box, Typography } from "@mui/material";
import defaultCoverImage from '../../images/cafeCoverImage.png';
import GetCafeImage from '../../services/GetCafeImage';

function CoverImage({ cafe }) {
    const [profileImage, setProfileImage] = useState();
    const [coverImage, setCoverImage] = useState();

    useEffect(() => {
        const fetchImages = async () => {
            try {
                if (cafe?.profileImage) {
                    const profileImageKey = await GetCafeImage(cafe.profileImage);
                    setProfileImage(profileImageKey);
                }
                
                if (cafe?.coverImage) {
                    const coverImageKey = await GetCafeImage(cafe.coverImage);
                    setCoverImage(coverImageKey);
                }
            } catch (error) {
                console.error("Failed to fetch image URLs:", error);
            }
        };
        fetchImages();
    }, [cafe]);

    return (
        <Box
            sx={{
                position: 'relative',
                overflow: 'hidden',
                width: '100%',
                height: { xs: '300px', sm: '400px', md: '500px' },  
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Box
                component="img"
                sx={{
                    top: 0,
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                }}
                src={coverImage}
                alt="Banner Image"
            />

            <Box
                sx={{
                    position: 'absolute',
                    bottom: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    background: 'linear-gradient(to top, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0))',
                    zIndex: 1,
                }}
            />

            <Avatar
                sx={{
                    width: { xs: 150, sm: 200, md: 250 },  
                    height: { xs: 150, sm: 200, md: 250 },
                    position: 'absolute',
                    bottom: { xs: 70, sm: 40, md: 160 },  
                    left: '50%',
                    transform: 'translateX(-50%)',
                    border: '2px solid white',
                    zIndex: 2,
                }}
                src={profileImage}
                alt="Avatar"
            />

            <Box
                sx={{
                    position: 'absolute',
                    bottom: { xs: 20, sm: 40, md: 50 },  
                    left: '50%',
                    transform: 'translateX(-50%)',
                    zIndex: 2,
                    textAlign: 'center',  
                }}
            >
                <Typography variant="h4" fontWeight={800} fontFamily={'serif'} color="white" noWrap sx={{
                    fontSize:{md:'5rem', xs:'2rem'}
                }}>
                    {cafe.name}
                </Typography>
            </Box>
        </Box>
    );
}

export default CoverImage;
