import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Container, Box, Grid } from '@mui/material';
import CafeImagesBanner from './profile/CafeImagesBanner';
import CoverImage from './profile/CoverImage';
import Informations from './profile/Informations';
import Time from './profile/Time';
import getCafeDetails from '../services/GetCafeDetails';

function Cafe() {
  const [cafe, setCafe] = useState({});
  const { id: cafeId } = useParams();

  useEffect(() => {
    const fetchCafeDetails = async () => {
      try {
        const cafeDetails = await getCafeDetails(cafeId);
        setCafe(cafeDetails);
        console.log(cafeDetails);
      } catch (error) {
        console.error("Error fetching cafe details:", error);
      }
    };

    fetchCafeDetails();
  }, [cafeId]);

  return (
    <>
      <CoverImage cafe={cafe}/>
      <Container maxWidth="xl" sx={{ mt: 2 }}>
        <Box display="flex" flexDirection="column">
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4} md={3}>
              <Informations cafe={cafe} />
            </Grid>
            <Grid item xs={12} sm={8} md={6}>
              <CafeImagesBanner cafe={cafe} />
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <Time cafe={cafe} />
            </Grid>
          </Grid>
        </Box>
      </Container>
    </>
  );
}

export default Cafe;
