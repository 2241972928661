import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { CssBaseline, Box } from '@mui/material';
import GetCafeImage from '../../services/GetCafeImage';
import "../styles/CafeImageBanner.css";

function CafeImagesBanner({ cafe }) {
  const [imageUrls, setImageUrls] = useState([]);
  
  useEffect(() => {
    const fetchImageUrls = async () => {
      try {
        if (cafe?.cafeImages?.length > 0) {
          const keys = cafe.cafeImages[0].imageKeys;
          const urls = await Promise.all(keys.map(key => GetCafeImage(key)));
          console.log(urls)
          setImageUrls(urls);
        }
      } catch (error) {
        console.error("Failed to fetch image URLs:", error);
      }
    };

    fetchImageUrls();
  }, [cafe]);

  const settings = {
    dots: false,
    infinite: true,
    speed: 1500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 6000,
  };

  return (
    <Box>
      <CssBaseline />
      <Box sx={{ height: '100%', width: '100%', overflow: 'hidden', position: 'relative' }}>
        <Slider {...settings}>
          {imageUrls.map((url, index) => (
            <Box
              key={index}
              className="slide"
              sx={{
                backgroundImage: `url(${url})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                height: '688px',
                display: 'flex',
              }}
            >
            </Box>
          ))}
        </Slider>
      </Box>
    </Box>
  );
}

export default CafeImagesBanner;
