import React, { useEffect, useState } from 'react';
import { Grid, Container, CssBaseline } from "@mui/material";
import Pagination from './components/Pagination'
import CafeGrid from './components/CafeGrid'
import GetAllCafes from './services/GetAllCafes'
import Banner from './components/Banner'

function Screen() {

    const [cafes, setCafes] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);

    useEffect(() => {
        const fetchCafes = async () => {
            try {
                const { content, totalPages } = await GetAllCafes();
                setCafes(content);
                setTotalPages(totalPages);
                return cafeData;
            } catch (error) {

            }
        }
        fetchCafes();
    }, [])

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };


    return (
        <Container disableGutters maxWidth="2xl">
            <CssBaseline />
            <Banner />
            <Grid container spacing={5}>
                <Grid item xs={12}>
                    <CafeGrid cafes={cafes} />
                </Grid>
                <Grid item xs={12} >
                    <Pagination
                        currentPage={currentPage}
                        totalPages={totalPages}
                        onPageChange={handlePageChange} />
                </Grid>
            </Grid>
        </Container>
    ) 
}

export default Screen