import ApiInstance from "../configs/apiInstance";

const GetAllCafes = async () => {
    try {
        const response = await ApiInstance.cafeService.get();
        const { content, totalPages } = response.data;
        return { content, totalPages };
        } catch (error) { 
            console.log(error);   
    }
}

export default GetAllCafes;