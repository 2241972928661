import ApiInstance from "../configs/apiInstance";

const GetCafeImage = async (key) => {
  try {
    const response = await ApiInstance.userAwsImageService.get(`/cafe-image/${key}`);
    const imageUrl = response.data.url;
    return imageUrl;
  } catch (error) {
    console.error('Failed to fetch uploaded images:', error);
    throw error;
  }
};

export default GetCafeImage;
