import { Box, Divider, Stack, Typography } from '@mui/material'
import React from 'react'
import HomeIcon from '@mui/icons-material/Home';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import CallIcon from '@mui/icons-material/Call';
import StorefrontIcon from '@mui/icons-material/Storefront';
import LanguageIcon from '@mui/icons-material/Language';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import FmdGoodIcon from '@mui/icons-material/FmdGood';

function Informations({cafe}) {
    return (
        <>
        <Box
            height={300}
            display="flex"
            flexDirection="column"
            p={2}
            mb={1}
            sx={{
                background: 'linear-gradient(45deg, rgba(80, 242, 196, 0.5), rgba(38, 208, 227, 0.5), rgba(117, 122, 255, 0.5))'
            }}
        >
            <Stack spacing={2}>
                <Stack spacing={1} alignItems={'flex-start'}>
                    <Typography fontWeight={800} variant='h5'>Description</Typography>
                    <Typography variant='body1'>{cafe.description}</Typography>
                    <Divider sx={{ bgcolor: 'white' }} />
                </Stack>
            </Stack>
        </Box>
        <Box
        height={380}
        justifyContent={'center'}
        display="flex"
        flexDirection="column"
        p={2}
        mb={2}
        sx={{
            background: 'linear-gradient(135deg, rgba(80, 242, 196, 0.5), rgba(38, 208, 227, 0.5), rgba(117, 122, 255, 0.5))'
        }}
    >
        <Stack spacing={2}>
            <Stack direction={'row'} spacing={2} alignItems={'center'}>
                <HomeIcon />
                <Typography variant='body1'>{cafe.address}</Typography>
            </Stack>
            <Stack direction={'row'} spacing={2} alignItems={'center'}>
                <AlternateEmailIcon />
                <Typography variant='body1'>{cafe.email}</Typography>
            </Stack>
            <Stack direction={'row'} spacing={2} alignItems={'center'}>
                <CallIcon />
                <Typography variant='body1'>{cafe.contactNumber}</Typography>
            </Stack>
            <Stack direction={'row'} spacing={2} alignItems={'center'}>
                <LanguageIcon />
                <Typography variant='body1'>{cafe.websiteURL}</Typography>
            </Stack>
            <Stack direction={'row'} spacing={2} alignItems={'center'}>
                <FacebookIcon />
                <Typography variant='body1'>{cafe.facebookURL}</Typography>
            </Stack>
            <Stack direction={'row'} spacing={2} alignItems={'center'}>
                <InstagramIcon />
                <Typography variant='body1'>{cafe.instagramURL}</Typography>
            </Stack>
            <Stack direction={'row'} spacing={2} alignItems={'center'}>
                <YouTubeIcon />
                <Typography variant='body1'>{cafe.youtubeURL}</Typography>
            </Stack>
            <Stack direction={'row'} spacing={2} alignItems={'center'}>
                <FmdGoodIcon />
                <Typography variant='body1'>{cafe.googleMapURL}</Typography>
            </Stack>
        </Stack>
    </Box>
    </>
    )
}

export default Informations