import React from "react";
import { Box, Container, Pagination as MuiPagination } from "@mui/material";

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
    const handleChange = (event, value) => {
        onPageChange(value);
    };

    return (
        <Container maxWidth="xl">
            <Box display="flex" sx={{ justifyContent: "center", marginBottom: 4 }}>
                <MuiPagination
                    count={totalPages}
                    page={currentPage}
                    onChange={handleChange}
                    variant="outlined"
                    shape="circular"
                    sx={{
                        "& .MuiPaginationItem-root": {
                            color: "rgba(218, 7, 28, 0.9)",  
                        },
                        "& .MuiPaginationItem-outlined": {
                            backgroundColor: "white",  
                            borderColor: "transparent",
                        },
                        "& .Mui-selected": {
                            backgroundColor: "rgba(241, 223, 30, 0.9)",  
                            color: "rgba(218, 7, 28, 0.9)",
                        },
                    }}
                />
            </Box>
        </Container>
    );
};

export default Pagination;
